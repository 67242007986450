/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/naming-convention */

import { AxiosError } from 'axios';
import { toCamelOrSnakeCase } from '@/shared/lib/toCamelOrSnakeCase';
import { alertHandler } from '@/shared/lib/alertHandler';
import { authService, baseService } from '@/shared/api/api';
import { RoutesAuth } from '@/shared/api/routes';
import { Tokens } from '@/shared/api/types';
import { tokensStore } from '@/shared/lib/tokens';
import { getError } from '@/shared/lib/getError';
import { userStateStore, UserStateStore } from './userState';
import { ROUTES } from '../routes';
import {
  CompanyInfo,
  CreateUser,
  RegisterData,
  TempTokens,
  UserData,
  UserInfo,
  UserInfoLogin,
} from '../types';
import { userSyncStore, UserSyncStore } from './userSync';

class UserAsync {
  state: UserStateStore;
  sync: UserSyncStore;

  constructor(storeState: UserStateStore, storeSync: UserSyncStore) {
    this.state = storeState;
    this.sync = storeSync;
  }

  /**
   * Выход пользователя из системы
   */
  async fetchLogout() {
    try {
      this.state.loadings.logout = true;

      await baseService.post(ROUTES.Logout);

      this.sync.logout();
    } catch (error) {
      alertHandler.addAlert({ alert: error });
    } finally {
      this.state.loadings.logout = false;
    }
  }

  /**
   * Получение списка пользователей компании к которой принадлежит вошедший пользователь
   */
  async fetchUsersList() {
    try {
      this.state.loadings.usersInfo = true;

      const response = await baseService.get<UserInfo[]>(ROUTES.UsersList, {
        params: {
          company_id: this.state.companyId,
        },
      });
      const data = toCamelOrSnakeCase<UserInfo[]>(response.data, true);

      this.state.usersInfo = data;
    } catch (error) {
      alertHandler.addAlert({ alert: error });
    } finally {
      this.state.loadings.usersInfo = false;
    }
  }

  /**
   * Получение информации о текущем пользователе
   */
  async fetchUserInfo() {
    try {
      this.state.loadings.userData = true;

      const response = await baseService.get(RoutesAuth.UserInfo);
      const data = toCamelOrSnakeCase<UserData>(response.data.user, true);

      this.state.userId = data.userId;
      this.state.companyId = data.companyId;
      this.state.userInfo = data;
    } catch {
      throw new Error('Error tokens');
    } finally {
      this.state.loadings.userData = false;
    }
  }

  /**
   * Получить информацию о компании
   */
  async fetchCompanyInfo() {
    try {
      this.state.loadings.companyInfo = true;

      const response = await baseService.get<CompanyInfo>(ROUTES.CompanyInfo, {
        params: {
          company_id: this.state.companyId,
        },
      });
      const data = toCamelOrSnakeCase<CompanyInfo>(response.data, true);

      this.state.companyInfo = data;

      if (this.state.userInfo === null) {
        this.fetchUsersList();
      }
    } catch (error) {
      alertHandler.addAlert({ alert: error });
    } finally {
      this.state.loadings.companyInfo = false;
    }
  }

  async fetchLogin(email: string, password: string) {
    try {
      this.state.loadings.login = true;

      const response = await baseService.post<Tokens>(
        RoutesAuth.TokensByPassword,
        {
          email,
          password,
        },
      );
      const data = toCamelOrSnakeCase<Tokens>(response.data, true);

      tokensStore.setAccessTokens(data.accessToken);
      tokensStore.setRefreshTokens(data.refreshToken);

      await this.fetchUserInfo();

      return 200;
    } catch (error) {
      alertHandler.addAlert({ alert: error });
      return (error as AxiosError)?.response?.status || -1;
    } finally {
      this.state.loadings.login = false;
    }
  }

  async fetchLoginStepEmail(email: string, is_recovery: boolean) {
    try {
      this.state.loadings.tokensByCode = true;

      const response = await authService.post<TempTokens>(RoutesAuth.SendCode, {
        email,
        is_recovery,
      });
      const data = toCamelOrSnakeCase<TempTokens>(response.data, true);

      this.state.temporaryToken = data.temporaryToken;

      alertHandler.addAlert({
        defaultText: 'auth.repeatCode',
        status: 'success',
      });

      return {
        status: 200,
        error: null,
      };
    } catch (error) {
      alertHandler.addAlert({ alert: error });
      return {
        status: (error as AxiosError)?.response?.status || -1,
        error: getError(error),
      };
    } finally {
      this.state.loadings.tokensByCode = false;
    }
  }

  async fetchLoginStepCode(code: string, isFastUserInfo = true) {
    try {
      this.state.loadings.tokensByCode = true;

      const response = await authService({
        method: 'post',
        url: RoutesAuth.TokensByCode,
        data: {
          code,
        },
        headers: {
          Authorization: `${tokensStore.getTypeTokens()} ${this.state.temporaryToken}`,
        },
      });
      const data = toCamelOrSnakeCase<Tokens>(response.data, true);

      tokensStore.setAccessTokens(data.accessToken);
      tokensStore.setRefreshTokens(data.refreshToken);

      this.state.temporaryToken = null;

      if (isFastUserInfo) {
        await this.fetchUserInfo();
      }

      return 200;
    } catch (error) {
      alertHandler.addAlert({ alert: error });
      return (error as AxiosError)?.response?.status || -1;
    } finally {
      this.state.loadings.tokensByCode = false;
    }
  }

  async fetchLoginStepPassword(email: string, password: string) {
    try {
      this.state.loadings.tokensByCode = true;

      await baseService.post(RoutesAuth.RecoveryPassword, {
        email,
        password,
      });

      return 200;
    } catch (error) {
      alertHandler.addAlert({ alert: error });
      return (error as AxiosError)?.response?.status || -1;
    } finally {
      this.state.loadings.tokensByCode = false;
    }
  }

  async fetchRegister(registerData: RegisterData) {
    try {
      this.state.loadings.register = true;

      const requestData = {
        email: registerData.email.trim(),
        password: registerData.password,
        company_name: registerData.companyName.trim(),
        first_name: registerData.firstName.trim(),
        last_name: registerData.lastName.trim(),
        middle_name: registerData.middleName.trim(),
        position: registerData.position.trim(),
      };

      const response = await baseService.post<Tokens>(
        RoutesAuth.Register,
        requestData,
      );

      const data = toCamelOrSnakeCase<Tokens>(response.data, true);

      tokensStore.setAccessTokens(data.accessToken);
      tokensStore.setRefreshTokens(data.refreshToken);

      await this.fetchUserInfo();
      return 200;
    } catch (error) {
      alertHandler.addAlert({ alert: error });
      return (error as AxiosError)?.response?.status || -1;
    } finally {
      this.state.loadings.register = false;
    }
  }

  /**
   * Метод добавления нового пользователя в компанию
   * @param user - данные о новом пользователи компании
   */
  async createNewUser(user: CreateUser) {
    try {
      this.state.loadings.createUser = true;

      await baseService.post<UserInfoLogin>(ROUTES.CreateUser, {
        first_name: user.firstName,
        last_name: user.lastName,
        middle_name: user.middleName,
        email: user.email,
        company_id: this.state.companyId,
        settings_id: this.state.companyId,
        position: user.position,
      });

      await this.fetchUsersList();
    } catch (error) {
      alertHandler.addAlert({ alert: error });
    } finally {
      this.state.loadings.createUser = false;
    }
  }

  /**
   * Метод смены пароля
   * @param user - данные о новом пользователи компании
   */
  async changePassword(password: string, oldPassword: string) {
    try {
      this.state.loadings.changePassword = true;
      const userEmail = this.state.userInfo?.email;
      await baseService.post(ROUTES.ChangePassword, {
        email: userEmail,
        password,
        old_password: oldPassword,
      });

      await this.fetchUserInfo();
      return 200;
    } catch (error) {
      alertHandler.addAlert({ alert: error });
    } finally {
      this.state.loadings.changePassword = false;
    }
  }

  /**
   * Метод изменения доступа пользователей к сервису
   * @param access - данные о новом статусе доступа
   */
  async changeAccessOfUsers(access: string) {
    try {
      // this.state.loadings.createUser = true;

      await baseService.post<UserInfoLogin>(ROUTES.LockProject, {
        block_value: access,
      });

      await this.fetchUsersList();
    } catch (error) {
      alertHandler.addAlert({ alert: error });
    } finally {
      // this.state.loadings.createUser = false;
    }
  }
}

export const userAsyncStore = new UserAsync(userStateStore, userSyncStore);
export type UserAsyncStore = typeof userAsyncStore;
