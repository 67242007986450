import axios from 'axios';
import { tokensStore } from '../lib/tokens/tokens';
import { alertHandler } from '../lib/alertHandler';
import { Tokens } from './types';
import { RoutesAuth } from './routes';
import { toCamelOrSnakeCase } from '../lib/toCamelOrSnakeCase';
import { TokensKeys } from '../lib/tokens/types';

const BASE_URL = process.env.NEXT_PUBLIC_BACK_PROD;

/**
 * Конфигурация базового сервиса для запросов
 */
export const baseService = axios.create({
  baseURL: BASE_URL,
});

/**
 * Конфигурация сервиса для авторизации
 * Используем если нужен чистый сервис для авторизации
 * без перехватчика запросов и обновления токенов
 */
export const authService = axios.create({
  baseURL: BASE_URL,
});

/**
 * Запрос для обновления токена
 * @returns Возвращает токены access и refresh
 */
async function refreshTokens() {
  const refreshToken = tokensStore.getRefreshTokens();
  const tokenType = tokensStore.getTypeTokens();

  if (refreshToken === null) {
    alertHandler.addAlert({ defaultText: '' });
  }

  try {
    const response = await fetch(`${BASE_URL}${RoutesAuth.Refresh}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${tokenType} ${refreshToken}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const data = toCamelOrSnakeCase<Tokens>(await response.json(), true);

    tokensStore.setAccessTokens(data.accessToken);
    tokensStore.setRefreshTokens(data.refreshToken);

    return {
      [TokensKeys.AccessToken]: data.accessToken,
      [TokensKeys.RefreshToken]: data.refreshToken,
    };
  } catch (error) {
    alertHandler.addAlert({ alert: error });
    tokensStore.removeTokens();

    return {
      [TokensKeys.AccessToken]: null,
      [TokensKeys.RefreshToken]: null,
    };
  }
}

/**
 * Настройка перехватчика для добавления токена в заголовок
 */
baseService.interceptors.request.use(
  (request) => {
    const accessToken = tokensStore.getAccessTokens();
    const tokenType = tokensStore.getTypeTokens();
    if (accessToken) {
      request.headers.Authorization = `${tokenType} ${accessToken}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

/**
 * Перехватчик для обработки обновления токена при 401 Unauthorized
 */
baseService.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const originalRequest = error.config;

      // Попытка обновить токен только один раз
      if (!originalRequest._retry) {
        originalRequest._retry = true;
        const newAccessToken = await refreshTokens();

        if (newAccessToken[TokensKeys.AccessToken]) {
          const tokenType = tokensStore.getTypeTokens();

          // Повторно устанавливаем новый токен и отправляем запрос снова
          originalRequest.headers.Authorization = `${tokenType} ${newAccessToken}`;
          return baseService(originalRequest);
        }

        // Если обновить токен не удалось, удаляем оба токена
        tokensStore.removeTokens();
      }
    }
    return Promise.reject(error);
  },
);

/**
 * Основная функция для fetch с автоматической обработкой токенов и 401 ошибок
 */
export async function fetchService<T>(
  url: string,
  options: RequestInit = {},
  retry = true,
) {
  const accessToken = tokensStore.getAccessTokens();
  const tokenType = tokensStore.getTypeTokens();

  const headers = new Headers(options?.headers);
  headers.set('Content-Type', 'application/json');

  if (accessToken) {
    headers.set('Authorization', `${tokenType} ${accessToken}`);
  }

  try {
    const response = await fetch(`${BASE_URL}${url}`, {
      ...options,
      headers,
    });

    if (response.status === 401 && retry) {
      // Попытка обновить токен
      const newAccessToken = await refreshTokens();

      if (newAccessToken?.[TokensKeys.AccessToken]) {
        headers.set('Authorization', `${tokenType} ${accessToken}`);
        return fetchService<T>(url, { ...options, headers }, false);
      }
    }

    if (!response.ok) {
      const errorData = await response.json();
      throw errorData.message;
    }

    return await response;
  } catch (error) {
    alertHandler.addAlert({ alert: error });
    throw error;
  }
}
