import { Label } from '@/shared/ui/Label';
import { storeAdmin } from '@/shared/store/admin';
import { useTranslations } from 'next-intl';
import styled from './TariffPlan.module.scss';
import { Container, StartButton } from './styled';

export function TariffPlan() {
  const onClickTariffPlan = () => {
    storeAdmin.isOpenModalContactWithUs = true;
  };
  const t = useTranslations('landing.tariffBlock');
  return (
    <div style={{ marginBottom: '30px' }} id="usage">
      <Label text={t('tarifTitle')} />
      <div className={styled.title}>{t('onpremiseTitle')}</div>

      <div className={styled.cards}>
        <div className={styled.root}>
          <div className={styled.containerTitle}>{t('cost')}</div>
          <div className={styled.containerBody}>
            <Container>
              <StartButton variant="text" onClick={onClickTariffPlan}>
                start
              </StartButton>
            </Container>
            <div className={styled.subtitle}>{t('startTime')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
