export enum ROUTES {
  Login = '/users/login',
  Register = '/users/register',
  CompanyInfo = '/company/',
  UsersList = '/users/list',
  CreateUser = '/company/create_user',
  ChangePassword = '/users/update_password',
  Logout = '/auth/logout',
  LockProject = '/admin/lock_project',
}
