import { Box, styled, Button } from '@mui/material';

export const Container = styled(Box)({
  width: '253px',
  height: '43px',
  borderRadius: '39px',
  border: 'solid 1px var(--white)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: '0.5s background-color',
  '&:hover': {
    background: 'var(--grey9)',
  },
  '@media (max-width: 768px)': {
    width: '215px',
  },

  '@media (max-width: 556px)': {
    width: '75px',
    height: '30px',
  },
});

export const ContactButton = styled(Button)({
  fontSize: '17px',
  fontFamily: 'Montserrat-Regular, sans-serif !important',
  color: 'white !important',
  '@media (max-width: 768px)': {
    fontSize: '14px',
  },

  '@media (max-width: 556px)': {
    fontSize: '7px',
  },
});
