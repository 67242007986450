import { Label } from '@/shared/ui/Label';
import { logoArray } from '@/shared/config/landing';
import Image from 'next/image';
import { Container } from '@/shared/ui/Container';
import {
  Directum,
  Cassandra,
  Wiki,
  MlFLow,
  WebSoft,
  Mosh,
  OneAss,
  Arxiv,
  Wss,
  Gitlab,
  Word,
  Wolfram,
  Outlook,
  Confluence,
  Stories,
  Defi,
  Nukomeet,
  Exel,
  Azure,
  Oracle,
  Telegram,
  Figma,
  Ai21labs,
} from '@/public/assets/icons/LandingIcons/IntegrationsIcons';
import { useLocaleSwitcher } from '@/shared/hooks/useLocaleSwitcher';
import { useTranslations } from 'next-intl';
import styled from './Intergations.module.scss';

interface IntegrationsProps {
  type: 'mobile' | 'laptop';
}

export const Integrations = (props: IntegrationsProps) => {
  const { type } = props;
  const { isOnlyEn } = useLocaleSwitcher();
  const t = useTranslations('landing.integrationBlock');
  if (type === 'mobile') {
    return (
      <>
        <Container>
          <Label text={t('integrationTitle')} id="systems" />

          <p className={styled.description}>{t('integrationSubTitle')}</p>
        </Container>

        <Container>
          <div className={styled.gradient} />

          <div className={styled.logoContainer}>
            <div className={styled.logo}>
              <Telegram />
            </div>
            <div className={styled.logo}>
              <Figma />
            </div>
            <div className={styled.logo}>
              <Ai21labs />
            </div>
            <div className={styled.logo}>
              <Arxiv />
            </div>
            <div className={styled.logo}>
              <Azure />
            </div>
            <div className={styled.logo}>
              <Mosh />
            </div>
            <div className={styled.logo}>
              <Nukomeet />
            </div>
            <div className={styled.logo}>
              <Gitlab />
            </div>
            <div className={styled.logo}>
              <Defi />
            </div>
            <div className={styled.logo}>
              <Stories />
            </div>
            <div className={styled.logo}>
              <Cassandra />
            </div>
            <div className={styled.logo}>
              <Confluence />
            </div>
            <div className={styled.logo}>
              <Word />
            </div>
            <div className={styled.logo}>
              <Exel />
            </div>
            <div className={styled.logo}>
              <Outlook />
            </div>
            <div className={styled.logo}>
              <Wolfram />
            </div>
            <div className={styled.logo}>
              <Wiki />
            </div>
            <div className={styled.logo}>
              <MlFLow />
            </div>
            {!isOnlyEn && (
              <div className={styled.logo}>
                <Wss />
              </div>
            )}
            {!isOnlyEn && (
              <div className={styled.logo}>
                <WebSoft />
              </div>
            )}
            {!isOnlyEn && (
              <div className={styled.logo}>
                <OneAss />
              </div>
            )}
            <div className={styled.logo}>
              <Oracle />
            </div>
            {!isOnlyEn && (
              <div className={styled.logo}>
                <Directum />
              </div>
            )}
          </div>
        </Container>

        <Container>
          <p className={styled.additional}>{t('integrationHelpText')}</p>
        </Container>
      </>
    );
  }

  return (
    <>
      <Container>
        <Label text={t('integrationTitle')} />

        <div className={styled.infoContainer}>
          <p className={styled.description} style={{ padding: 0 }}>
            {t('integrationSubTitle')}
          </p>

          <p className={styled.additional} style={{ padding: 0 }}>
            {t('integrationHelpText')}
          </p>
        </div>
      </Container>

      <Container>
        <div className={styled.gradient} />

        <div className={styled.logoContainer}>
          {logoArray.map((logo) => (
            <Image
              key={logo.alt}
              className={styled.logo}
              src={logo.icon}
              alt={logo.alt}
              loading="lazy"
            />
          ))}
        </div>
      </Container>
    </>
  );
};
