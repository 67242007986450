import { storeAdmin } from '@/shared/store/admin';
import { useTranslations } from 'next-intl';
import { Container, ContactButton } from './styled';

export const ContactWithUs = () => {
  const t = useTranslations('landing.footerBlock');
  const onOpenContactModal = () => {
    storeAdmin.isOpenModalContactWithUs = true;
  };
  return (
    <Container onClick={onOpenContactModal}>
      <ContactButton variant="text">{t('contactWithUs')}</ContactButton>
    </Container>
  );
};
