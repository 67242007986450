import { localStorageWrapper } from '../storageWrapper';
import { TokensKeys } from './types';

class Tokens {
  private accessTokens: string | null = null;
  private refreshTokens: string | null = null;
  private typeTokens = 'bearer';

  constructor() {
    this.accessTokens = localStorageWrapper.get(TokensKeys.AccessToken) || null;
    this.refreshTokens =
      localStorageWrapper.get(TokensKeys.RefreshToken) || null;
    this.typeTokens = localStorageWrapper.get(TokensKeys.TypeToken) || 'bearer';
  }

  /**
   * Метод получения токена доступа
   */
  public getAccessTokens() {
    return this.accessTokens;
  }

  /**
   * Метод получения токена обновления
   */
  public getRefreshTokens() {
    return this.refreshTokens;
  }

  /**
   * Метод получения типа токена
   */
  public getTypeTokens() {
    return this.typeTokens;
  }

  /**
   * Метод установления токена доступа
   */
  public setAccessTokens(value: string) {
    this.accessTokens = value;
    localStorageWrapper.set(TokensKeys.AccessToken, value);
  }

  /**
   * Метод установления токена обновления
   */
  public setRefreshTokens(value: string) {
    this.refreshTokens = value;
    localStorageWrapper.set(TokensKeys.RefreshToken, value);
  }

  /**
   * Метод установления типа токена
   */
  public setTypeTokens(value: string) {
    this.typeTokens = value;
    localStorageWrapper.set(TokensKeys.TypeToken, value);
  }

  /**
   * Удалить все сохраненные токены
   */
  public removeTokens() {
    this.accessTokens = null;
    this.refreshTokens = null;
    this.typeTokens = 'bearer';

    localStorageWrapper.remove(TokensKeys.AccessToken);
    localStorageWrapper.remove(TokensKeys.RefreshToken);
    localStorageWrapper.remove(TokensKeys.TypeToken);
  }
}

export const tokensStore = new Tokens();
