export enum ROUTES {
  Login = '/users/login',
  Register = '/users/register',
  DocumentsList = '/document/company/list',
  UploadFile = '/document/upload_file',
  DeleteDocument = '/document/delete',
  ReloadDocument = '/document/reload_file',
  DownloadDocument = '/document/download',
  Folders = '/company/collection',
  CreateFolder = '/company/collection/create',
  DeleteFolder = '/company/collection/delete',
  CompanyInfo = '/company/',
  UsersList = '/users/list',
  _UsersRequest = '/users/request',
  _UsersSearchDocument = '/users/search_document',
  OnSendMessage = '/v1/semantic_search',
  SendNewUserInfo = '/company/create_user',
  SendMessageToContact = '/users/send_notification',
  CreateUserByToken = 'auth/create_password',
}
