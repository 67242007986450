import { Label } from '@/shared/ui/Label';
import { useTranslations } from 'next-intl';
import styled from './Using.module.scss';
import { CardUsing } from '../CardUsing';

export function Using() {
  const t = useTranslations('landing.saftyBlock');
  return (
    <div className={styled.root} id="usage">
      <Label text={t('saftyTitle')} />
      <div className={styled.subtitle}>{t('saftySubTitle')}</div>

      <div className={styled.cards}>
        <CardUsing title={t('cloudTitle')} subtitle={t('cloudText')} isOne />
        <CardUsing title={t('onprimesTitle')} subtitle={t('onprimesText')} />
      </div>
    </div>
  );
}
