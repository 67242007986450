import { Loader } from '@/shared/ui/Loader';
import { useTransition } from 'react';
import { useRouter } from 'next/navigation';
import { userStore } from '@/shared/store/user/userStore';
import { navigation } from '@/shared/lib/navigation';
import { useTranslations } from 'next-intl';
import styled from './Login.module.scss';

export function Login() {
  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const t = useTranslations('landing');
  function getPathname() {
    if (userStore.userState.userInfo === null) {
      startTransition(() => {
        router.push(navigation.getAuthPath());
      });
      return;
    }

    startTransition(() => {
      router.push(navigation.getSearchPath());
    });
  }

  return (
    <button type="button" onClick={getPathname} className={styled.container}>
      <div className={styled.button_container} />
      {!isPending ? (
        <div className={styled.button}>{t('enter')}</div>
      ) : (
        <Loader color="inherit" />
      )}
    </button>
  );
}
