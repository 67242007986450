// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/exhaustive-deps */
import { MASKS } from '@/shared/config/masks';
import { useInput } from '@/shared/hooks/useInput';
import { storeAdmin } from '@/shared/store/admin';
import { SendMessageContact } from '@/shared/store/admin/Types/requestType';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

export function useContactWithUs() {
  const [postionScroll, setPositionScroll] = useState<number>(0);
  const name = useInput('', MASKS.letters);
  const company = useInput('', MASKS.letters);
  const email = useInput('');
  const comments = useInput('');
  const [helperText, setHelperText] = useState('');
  const t = useTranslations('landing.contactWithUsModal.errors');
  function onClickCloseModalHandler() {
    name.setValueInField('');
    company.setValueInField('');
    email.setValueInField('');
    comments.setValueInField('');
    storeAdmin.isOpenModalContactWithUs = false;
  }

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (storeAdmin.isOpenModalContactWithUs) {
      const { scrollY } = window;
      setPositionScroll(scrollY);
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = '100%';
    } else {
      document.body.style.position = '';
      document.body.style.top = `-${postionScroll}px`;
      window.scrollTo(0, postionScroll);
    }

    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.overflow = originalStyle;
    };
  }, [storeAdmin.isOpenModalContactWithUs]);

  function getValidateForm() {
    switch (true) {
      case name.value.length <= 1:
        name.setIsErrorInTrue();
        setHelperText(t('errorOfName'));
        return false;
      case company.value.length <= 2:
        company.setIsErrorInTrue();
        setHelperText(t('errorOfCompany'));
        return false;
      case !MASKS.email.test(email.value):
        email.setIsErrorInTrue();
        setHelperText(t('errorOfMail'));
        return false;
      case comments.value.length <= 1:
        name.setIsErrorInTrue();
        setHelperText(t('errorOfComments'));
        return false;
      default:
        setHelperText('');
        return true;
    }
  }

  async function onClickSubmitForm() {
    if (!getValidateForm()) return;
    const sendMessageData: SendMessageContact = {
      email: email.value,
      fullName: name.value,
      company: company.value,
      comments: comments.value,
    };
    await storeAdmin.sendMessageFromContactWithUs(sendMessageData);
  }

  useEffect(() => {
    if (
      !name.isError &&
      !email.isError &&
      !comments.isError &&
      !company.isError
    ) {
      setHelperText('');
    }
  }, [name.isError, email.isError, company.isError, comments.isError]);

  useEffect(() => {
    name.setValueInField('');
    company.setValueInField('');
    email.setValueInField('');
    comments.setValueInField('');
  }, [storeAdmin.isOpenModalContactWithUs]);
  return {
    onClickCloseModalHandler,
    name,
    email,
    comments,
    company,
    helperText,
    onClickSubmitForm,
  };
}
