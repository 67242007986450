import { observer } from 'mobx-react-lite';
import { storeAdmin } from '@/shared/store/admin';
import { IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import { MainAlert } from '@/shared/ui/MainAlert';
import { useTranslations } from 'next-intl';
import { ContactInput } from './components';
import {
  AcceptedButton,
  ButtonBox,
  LeftSideInfo,
  RightSideInfo,
} from './styled';
import { useContactWithUs } from './hooks/useContactWithUs';
import styles from './ContactWithUsModal.module.scss';
import { ContactWithUsType } from './ContactWithUs.types';

const ContactWithUsModal = observer(() => {
  const t = useTranslations('landing.contactWithUsModal');
  const {
    onClickCloseModalHandler,
    name,
    email,
    comments,
    company,
    helperText,
    onClickSubmitForm,
  } = useContactWithUs();
  const newHelperText =
    helperText || storeAdmin.errorServer.errorMessageContact;
  const CONTACT_WITH_US_DATA: ContactWithUsType[] = [
    {
      key: 1,
      label: t('fio') || '',
      placeholder: '',
      type: 'text',
      value: name.value,
      onChange: name.onChange,
    },
    {
      key: 2,
      label: t('company') || '',
      placeholder: '',
      type: 'text',
      value: company.value,
      onChange: company.onChange,
    },
    {
      key: 3,
      label: t('email') || '',
      placeholder: '',
      type: 'email',
      value: email.value,
      onChange: email.onChange,
    },
    {
      key: 4,
      label: t('comments') || '',
      placeholder: '',
      type: 'text',
      value: comments.value,
      onChange: comments.onChange,
      isMultiline: true,
      rowsCount: 5,
      maxRows: 6,
    },
  ];
  return (
    <Modal
      open={storeAdmin.isOpenModalContactWithUs}
      onClose={onClickCloseModalHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={styles.substrate}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      autoFocus
    >
      <>
        <div className={styles.container}>
          <LeftSideInfo>
            <div className={styles.header}>
              <div className={styles.headerContainer}>
                <Typography fontSize={25} whiteSpace="nowrap">
                  {t('Title')}
                </Typography>
                <IconButton
                  className={styles.iconButton}
                  onClick={onClickCloseModalHandler}
                >
                  <CloseIcon sx={{ color: 'var(--white)' }} fontSize="large" />
                </IconButton>
              </div>
              <Typography
                color="var(--grey4)"
                fontSize={12}
                style={{ width: '200px' }}
              >
                {t('subTitle')}
              </Typography>
            </div>
          </LeftSideInfo>
          <RightSideInfo>
            {CONTACT_WITH_US_DATA.map((data) => (
              <ContactInput {...data} key={data.key} />
            ))}
            <div style={{ height: '50px' }}>
              {newHelperText && (
                <div className={styles.alert}>
                  <MainAlert type="error">{newHelperText}</MainAlert>
                </div>
              )}
            </div>
            <ButtonBox>
              <Typography
                color="var(--grey4)"
                fontSize={10}
                style={{ width: '200px' }}
              >
                {t('sendText')}
              </Typography>
              <AcceptedButton onClick={onClickSubmitForm}>
                {t('sendButton')}
              </AcceptedButton>
            </ButtonBox>
          </RightSideInfo>
        </div>
      </>
    </Modal>
  );
});

export default ContactWithUsModal;
