import { Label } from '@/shared/ui/Label';
import { useTranslations } from 'next-intl';
import styled from './FunctionalityOfSystem.module.scss';

export const FunctionalityOfSystem = () => {
  const t = useTranslations('landing.functionBlock');
  return (
    <section className={styled.FunctionalityOfSystem} id="functional">
      <Label text={t('functionTitle')} />

      <div className={styled.listContainer}>
        <div className={styled.list}>
          <p className={styled.title}>{t('functionSubTitle')}</p>

          <ul className={styled.ul}>
            <li className={styled.li}>{t('firstFuncText')}</li>
            <li className={styled.li2}>{t('secondFuncText')}</li>
            <li className={styled.li2}>{t('thirdFuncText')}</li>
            <li className={styled.li}>{t('fourthFuncText')}</li>
            <li className={styled.li}>{t('fivesFuncText')}</li>
            <li className={styled.li}>{t('sixFuncText')}</li>
            <li className={styled.li}>{t('sevenFuncText')}</li>
          </ul>
        </div>

        <div className={styled.list}>
          <p className={styled.title}>{t('additionalyTitle')}</p>

          <ul className={styled.ul}>
            <li className={styled.li}>{t('fisrtAdditionalyText')}</li>
            <li className={styled.li}>{t('secondAdditionalyText')}</li>
            <li className={styled.li}>{t('thirdAdditionalyText')}</li>
            <li className={styled.li}>{t('fourthAdditionalyText')}</li>
          </ul>
        </div>
      </div>
    </section>
  );
};
