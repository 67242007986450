import { Container } from '@/shared/ui/Container';
import { storeAdmin } from '@/shared/store/admin';
import Link from 'next/link';
import { motion } from 'framer-motion';
import { useScreenSize } from '@/shared/hooks/useScreenSize';

import { navigation } from '@/shared/lib/navigation';
import { useTranslations } from 'next-intl';
import styled from './NavMenu.module.scss';

export function NavMenu() {
  const screenSize = useScreenSize();
  const t = useTranslations('landing');
  function onClickSetIsOpen() {
    storeAdmin.setIsOpenNavMenu();
  }

  function getPathname() {
    if (!storeAdmin.isVerifiedUser) return navigation.getAuthPath();

    return navigation.getSearchPath();
  }

  return (
    <motion.div
      initial={{ y: '-100%', x: '0%' }}
      transition={{
        type: 'tween',
        ease: 'linear',
        duration: screenSize.width > 819 ? 0.25 : 0,
      }}
      className={styled.main}
    >
      <Container>
        <div className={styled.root}>
          <div className={styled.actions}>
            <Link href={getPathname()} className={styled.login}>
              {t('enter')}
            </Link>
          </div>

          <div className={styled.navmenu}>
            <div className={styled.menu}>
              <a
                className={styled.label_container}
                onClick={onClickSetIsOpen}
                href="#product"
              >
                <div className={styled.label}>{t('burger.about')}</div>
              </a>

              <a
                className={styled.label_container}
                onClick={onClickSetIsOpen}
                href="#advantages"
              >
                <div className={styled.label}>{t('burger.preimush')}</div>
              </a>

              <a
                className={styled.label_container}
                onClick={onClickSetIsOpen}
                href="#systems"
              >
                <div className={styled.label}>{t('burger.integration')}</div>
              </a>

              <a
                className={styled.label_container}
                onClick={onClickSetIsOpen}
                href="#application"
              >
                <div className={styled.label}>{t('burger.areas')}</div>
              </a>

              <a
                className={styled.label_container}
                onClick={onClickSetIsOpen}
                href="#functional"
              >
                <div className={styled.label}>{t('burger.function')}</div>
              </a>

              <a
                className={styled.label_container}
                onClick={onClickSetIsOpen}
                href="#usage"
              >
                <div className={styled.label}>{t('burger.used')}</div>
              </a>
            </div>
            <div onClick={onClickSetIsOpen} className={styled.box} />
          </div>
        </div>
      </Container>
    </motion.div>
  );
}
