// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useScreenSize } from '@/shared/hooks/useScreenSize';
import { storeAdmin } from '@/shared/store/admin';
import { useTranslations } from 'next-intl';
import styled from './Burger.module.scss';

export function Burger() {
  const screenSize = useScreenSize();
  const t = useTranslations('landing.burger');
  function onClickSetIsOpen() {
    screenSize.width < 820 ? storeAdmin.setIsOpenNavMenu() : undefined;
  }

  return (
    <div className={styled.container}>
      <div onClick={onClickSetIsOpen}>
        <svg
          className={styled.root}
          viewBox="0 0 24 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 1H24M0 17H24M0 9H24M0 25H24" stroke="white" />
        </svg>
      </div>

      <div className={styled.menu}>
        <a
          className={styled.label_container}
          onClick={onClickSetIsOpen}
          href="#product"
        >
          <div className={styled.label}>{t('about')}</div>
        </a>

        <a
          className={styled.label_container}
          onClick={onClickSetIsOpen}
          href="#advantages"
        >
          <div className={styled.label}>{t('preimush')}</div>
        </a>

        <a
          className={styled.label_container}
          onClick={onClickSetIsOpen}
          href="#systems"
        >
          <div className={styled.label}>{t('integration')}</div>
        </a>

        <a
          className={styled.label_container}
          onClick={onClickSetIsOpen}
          href="#application"
        >
          <div className={styled.label}>{t('areas')}</div>
        </a>

        <a
          className={styled.label_container}
          onClick={onClickSetIsOpen}
          href="#functional"
        >
          <div className={styled.label}>{t('function')}</div>
        </a>

        <a
          className={styled.label_container}
          onClick={onClickSetIsOpen}
          href="#usage"
        >
          <div className={styled.label}>{t('used')}</div>
        </a>
      </div>
    </div>
  );
}
