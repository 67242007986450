'use client';

import { Logo } from '@/public/assets/icons/LandingIcons/Logo/Logo';
import { observer } from 'mobx-react-lite';
import { firstInfoItems, secondInfoItems } from '@/shared/config/landing';
import { useScreenSize } from '@/shared/hooks/useScreenSize';
import { LandingFooter } from '@/widgets/Landing/components/LandingFooter/LandingFooter';
import { Gradient } from '@/shared/ui/Gradient/Gradient';
import { storeAdmin } from '@/shared/store/admin';
import { motion } from 'framer-motion';
import AlertService from '@/shared/ui/AlertService/AlertService';
import { useTranslations } from 'next-intl';
import { Burger } from './components/Burger';
import { Login } from './components/Login';
import { Container } from '../../shared/ui/Container';
import { InteractiveInfo } from './components/InteractiveInfo/InteractiveInfo';
import { Integrations } from './components/Intergations/Intergations';
import styled from './Landing.module.scss';
import { FunctionalityOfSystem } from './components/FunctionalityOfSystem/FunctionalityOfSystem';
import { Using } from './components/Using';
import { InteractiveWithAi } from './components/InteractiveWithAi';
import { TariffPlan } from './components/TariffPlan';
import ContactWithUsModal from './components/ContactWithUsModal/ContactWithUsModal';
import { NavMenu } from './components/NavMenu';

export const Landing = observer(() => {
  const screenSize = useScreenSize();
  const t = useTranslations('landing');
  return (
    <>
      <NavMenu />
      <motion.div
        initial={{ marginTop: '0px' }}
        animate={{ marginTop: !storeAdmin.isOpenNavMenu ? '0px' : '243px' }}
        transition={{
          type: 'tween',
          ease: 'linear',
          duration: screenSize.width > 819 ? 0.25 : 0,
        }}
        className={styled.container}
      >
        <Gradient
          variant="blue3"
          width={screenSize.width > 819 ? 900 : 500}
          height={screenSize.width > 819 ? 900 : 500}
          top="50%"
          left="20%"
          zIndex={1}
        />
        <Gradient
          variant="violet"
          width={screenSize.width > 819 ? 915 : 515}
          height={screenSize.width > 819 ? 897 : 500}
          top="100%"
          left="0%"
          zIndex={3}
        />
        <Gradient
          variant="orange"
          width={screenSize.width > 819 ? 633 : 520}
          height={screenSize.width > 819 ? 585 : 486}
          top="50%"
          left="30%"
          zIndex={4}
        />

        <Gradient
          variant="yellow1"
          width={screenSize.width > 819 ? 633 : 417}
          height={screenSize.width > 819 ? 585 : 386}
          top="-10%"
          left="110%"
          zIndex={4}
        />
        <Gradient
          variant="violet2"
          width={screenSize.width > 819 ? 833 : 449}
          height={screenSize.width > 819 ? 985 : 550}
          top="-40%"
          left="80%"
          zIndex={4}
        />
        <Gradient
          variant="blue5"
          width={screenSize.width > 819 ? 1233 : 713}
          height={screenSize.width > 819 ? 1385 : 714}
          top="-40%"
          left="110%"
          zIndex={3}
        />
        <Container>
          <div className={styled.root} id="product">
            <div className={styled.header_container}>
              <div>
                <Logo />

                {typeof window !== undefined && (
                  <div className={styled.subtitle}>
                    {t('firstBlock.isolTitle')}
                  </div>
                )}
              </div>

              <div className={styled.nav_container}>
                <Burger />
              </div>
            </div>

            <div className={styled.title_container}>
              <h1 className={styled.title}>{t('firstBlock.sdTitle')}</h1>

              <h2 className={styled.subtitle}>{t('firstBlock.effectTitle')}</h2>

              <h3 className={styled.sub_subtitle}>
                {t('firstBlock.helpTitile')}
              </h3>
            </div>

            <div className={styled.login_container}>
              <Login />
            </div>
          </div>
        </Container>
      </motion.div>

      <Container>
        <InteractiveInfo
          title={t('secondBlock.findTitle')}
          subTitle={t('secondBlock.firstSubTitle')}
          infoItems={firstInfoItems({
            secondTitle: t('secondBlock.secondTitle'),
            secondSubTitle: t('secondBlock.secondSubTitle'),
            thirdTitle: t('secondBlock.thirdTitle'),
            thirdSubTitle: t('secondBlock.thirdSubTitle'),
            fourthTitle: t('secondBlock.fourthTitle'),
            fourthSubTitle: t('secondBlock.fourthSubTitle'),
          })}
          id="advantages"
        />
      </Container>

      <Integrations type={screenSize.width > 1440 ? 'laptop' : 'mobile'} />

      <Container>
        <InteractiveWithAi />
      </Container>

      <Container>
        <InteractiveInfo
          title={t('areaApplications.title')}
          infoItems={secondInfoItems({
            firstTitle: t('areaApplications.firstTitle'),
            firstText: t('areaApplications.firstText'),
            secondTitle: t('areaApplications.secondTitle'),
            secondText: t('areaApplications.secondText'),
            thirdTitle: t('areaApplications.thirdTitle'),
            thirdText: t('areaApplications.thirdText'),
          })}
          id="application"
        />
      </Container>

      <Container>
        <FunctionalityOfSystem />
      </Container>

      <Container>
        <Using />
      </Container>

      <Container>
        <TariffPlan />
      </Container>

      <LandingFooter />
      <ContactWithUsModal />
      <AlertService />
    </>
  );
});
