import { makeAutoObservable } from 'mobx';
import { CompanyInfoNullable, UserInfo, UserDataNullable } from '../types';

class UserState {
  loadings = {
    userData: false,
    tokensByPassword: false,
    tokensByCode: false,

    companyInfo: false,
    usersInfo: false,
    login: false,
    register: false,
    createUser: false,
    logout: false,

    changePassword: false,
  };

  temporaryToken: string | null = null;

  userId: number | null = null;

  companyId: number | null = null;

  userInfo: UserDataNullable = null;
  usersInfo: UserInfo[] = [];
  companyInfo: CompanyInfoNullable = null;

  constructor() {
    makeAutoObservable(this);
  }
}

export const userStateStore = new UserState();
export type UserStateStore = typeof userStateStore;
