const isOnlyEn = process.env.NEXT_PUBLIC_IS_ONLY_EN === 'True';

class Navigation {
  private locale: 'en' | 'ru' = isOnlyEn ? 'en' : 'ru';
  private pathname = '';

  private mainPath = '/';
  private homePath = '/home';
  private signInPath = '/signIn';
  private signUpPath = '/signUp';
  private authPath = '/auth';
  private recoveryPath = '/recovery';
  private defaultPath = '';
  private createPasswordPath = '/create-password';
  private technicalWorkPath = '/technicalWork';
  // Dashboard
  private controlDocumentsPath = '/controlDocuments';
  private controlCompanyPath = '/controlCompany';
  private controlUsersPath = '/controlUsers';
  private personalAccountPath = '/personalAccount';
  private searchPath = '/search';

  // Метод получения locale
  public getLocale() {
    return this.locale;
  }

  // Метод установки locale
  public setLocale(locale: 'en' | 'ru') {
    this.locale = locale;
  }

  // Метод получения текущего пути
  public getPathname() {
    return this.pathname;
  }

  // Метод установки текущего пути
  public setPathname(pathname: string) {
    this.pathname = pathname;
  }

  // Метод получения текущего пути без locale
  public getPathnameWithoutLocale() {
    const SUPPORTED_LOCALES = ['en', 'ru'];

    const segments = this.pathname.split('/').filter(Boolean);
    if (segments.length > 0 && SUPPORTED_LOCALES.includes(segments[0])) {
      segments.shift();
    }
    return `/${segments.join('/')}`; // Собираем путь обратно
  }

  // Метод для конкатенации путей с locale
  public getCurrentNavigationPage(pathname: string) {
    const newPathname = `/${this.locale + pathname}`;

    this.pathname = newPathname;
    return newPathname;
  }

  // Метод для получения начального пути
  public getMainPath() {
    return this.getCurrentNavigationPage(this.mainPath);
  }

  // Метод для получения home пути
  public getHomePath() {
    return this.getCurrentNavigationPage(this.homePath);
  }

  // Метод для получения пути авторизации
  public getSignInPath() {
    return this.getCurrentNavigationPage(this.signInPath);
  }

  // Метод для получения пути регистрации
  public getSignUpPath() {
    return this.getCurrentNavigationPage(this.signUpPath);
  }

  // Метод для получения пути авторизации
  public getAuthPath() {
    return this.getCurrentNavigationPage(this.authPath);
  }

  // Метод для получения пути восстановления пароля
  public getRecoveryPath() {
    return this.getCurrentNavigationPage(this.recoveryPath);
  }

  // Метод для получения пустого пути
  public getDefaultPath() {
    return this.getCurrentNavigationPage(this.defaultPath);
  }

  // Метод для получения пути создания пароля
  public getCreatePasswordPath() {
    return this.getCurrentNavigationPage(this.createPasswordPath);
  }

  // Метод для получения пути страницы документов
  public getControlDocumentsPath() {
    return this.getCurrentNavigationPage(this.controlDocumentsPath);
  }

  // Метод для получения пути страницы компании
  public getControlCompanyPath() {
    return this.getCurrentNavigationPage(this.controlCompanyPath);
  }

  // Метод для получения пути страницы пользователей
  public getControlUserPath() {
    return this.getCurrentNavigationPage(this.controlUsersPath);
  }

  // Метод для получения пути страницы ЛК
  public getPersonalAccountPath() {
    return this.getCurrentNavigationPage(this.personalAccountPath);
  }

  // Метод для получения пути поиска
  public getSearchPath() {
    return this.getCurrentNavigationPage(this.searchPath);
  }

  // Метод для получения пути технических работ
  public getTechnicalWorkPathPath() {
    return this.getCurrentNavigationPage(this.technicalWorkPath);
  }
}

export const navigation = new Navigation();
export type NavigationType = typeof navigation;
