import { usePathname, useRouter } from 'next/navigation';
import { navigation } from '@/shared/lib/navigation';
import { useLocale } from 'next-intl';
import { LanguageLabel, LanguageValue } from './types';

export function useLocaleSwitcher() {
  const router = useRouter();
  const locale = useLocale();
  const pathname = usePathname();
  const isOnlyEn = process.env.NEXT_PUBLIC_IS_ONLY_EN === 'True';

  function getPathnameWithoutLocale() {
    const SUPPORTED_LOCALES = ['en', 'ru'];

    const segments = pathname.split('/').filter(Boolean);
    if (segments.length > 0 && SUPPORTED_LOCALES.includes(segments[0])) {
      segments.shift();
    }
    return `/${segments.join('/')}`;
  }

  function toggleLocale() {
    const pathnameWithoutLocal = getPathnameWithoutLocale();
    // Если разрешён только английский язык,
    // то при необходимости меняем локаль и блокируем обработки тогглера
    if (isOnlyEn) {
      if (locale === LanguageValue.Ru) {
        navigation.setLocale(LanguageValue.Eng);
        router.push(`/${LanguageValue.Eng}${pathnameWithoutLocal}`);
      }

      return;
    }

    if (locale === LanguageValue.Ru) {
      navigation.setLocale(LanguageValue.Eng);
      router.push(`/${LanguageValue.Eng}${pathnameWithoutLocal}`);
    } else {
      navigation.setLocale(LanguageValue.Ru);
      router.push(`/${LanguageValue.Ru}${pathnameWithoutLocal}`);
    }
  }

  return {
    locale: locale === LanguageValue.Ru ? LanguageLabel.Ru : LanguageLabel.Eng,
    toggleLocale,
    isOnlyEn,
  };
}
