/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable max-statements */
/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/no-cycle */
import { localStorageWrapper } from '@/shared/lib/storageWrapper';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { toCamelOrSnakeCase } from '@/shared/lib/toCamelOrSnakeCase';
import { replaceKeysInArrayOfObjects } from '@/shared/lib/replaceKeysInArrayOfObjects';
import { downloadFile } from '@/shared/lib/downloadFile';
import { replaceUnderlineToSpace } from '@/shared/lib/replaceUnderlineToSpace';
import { parseMarkdown } from '@/shared/lib/parseMarkdown';
import {
  Admin,
  AlertStatus,
  LoadingStatus,
  StorageKey,
  storeAdmin,
} from '../index';
import {
  CreatePasswordRequest,
  CreateUserRequest,
  SendMessageContact,
  SettingsSearchProps,
  SignInRequest,
  SignUpRequest,
  UploadFileRequest,
} from '../Types/requestType';
import { HEADER_FORM_DATA, MethodRequest } from '../Types/types';
import { ROUTES } from '../routes';
import {
  CompanyInfo,
  CompanyInfoServer,
  DocumentsData,
  Folders,
  FoldersServer,
  SignInOrSignUpResponse,
  UploadFileResponst,
  UsersList,
  UsersListServer,
} from '../Types/responseType';
import { getUser } from '../utils/GetUser';
import { getDataFormatLLM } from '../utils/GetDataFormatLLM';

axios.defaults.baseURL = process.env.NEXT_PUBLIC_BACK_PROD;

axios.defaults.headers.post['Content-Type'] = 'application/json';

/**
 * Сбрасывает ошибки, связанные с поиском.
 *
 * @type {(this: Admin) => Promise<void>}
 * @this {Admin}  Экземпляр класса
 */
export async function setSettingsSearch(
  this: Admin,
  {
    returnCount,
    searchParams,
    searchParamsChunks,
    docsIndexes,
    docsWindow,
    maxOutputToken,
    companyCollection,
  }: SettingsSearchProps,
): Promise<void> {
  // Настройка объекта settingSearch
  this.settingSearch = {
    // Значения по умолчанию
    requestID: String(this.userId),
    useLlm: true,
    useLlmCache: true,
    docsWindowType: ['onDoc'],
    LLMParamsLookBack: 0,
    LLMParamsLookForward: 0,
    isJrCollection: false,
    integrationFolderName: companyCollection,
    // Выставляемые значения
    returnCount,
    docsIndexes,
    docsWindow,
    maxOutputToken,
    companyCollection,

    // Фильтрация параметров поиска (исключение 'all' при необходимости)
    searchParams: searchParams.length
      ? searchParams.filter((item) => item !== 'all')
      : ['all'],
    searchParamsChunks: searchParamsChunks.length
      ? searchParamsChunks.filter((item) => item !== 'all')
      : ['all'],
  };

  // Асинхронное сохранение настроек
  await localStorageWrapper.set('settingSearch', this.settingSearch);
}

/**
 * Получает информацию о компании.
 *
 * @type {(this: Admin) => Promise<void>}
 * @this {Admin}  Экземпляр класса
 */
export async function getCompanyInfo(this: Admin) {
  const requestData = {
    company_id: this.companyId,
  };

  const config: AxiosRequestConfig = {
    method: MethodRequest.Get,
    url: ROUTES.CompanyInfo,
    params: requestData,
  };

  const successCallback = (data: CompanyInfoServer) => {
    this.companyInfo = toCamelOrSnakeCase<CompanyInfo>(data, true);
  };

  await this.performRequest(config, successCallback);
}

/**
 * Получает список пользователей.
 *
 * @type {(this: Admin) => Promise<void>}
 * @this {Admin}  Экземпляр класса
 */
export async function getUsersList(this: Admin) {
  const requestData = {
    company_id: this.companyId,
  };

  const config: AxiosRequestConfig = {
    method: MethodRequest.Get,
    url: ROUTES.UsersList,
    params: requestData,
  };

  const successCallback = (data: UsersListServer[]) => {
    this.usersList = toCamelOrSnakeCase<UsersList[]>(data, true);
    this.userInfo = getUser(
      this.userId,
      toCamelOrSnakeCase<UsersList[]>(data, true),
    );
  };

  const failedCallback = (error: any) => {
    if (error?.response) {
      if (error?.response?.data?.message) {
        this.errorServer.errorMessageGetUsers = error?.response?.data?.message;
      } else {
        this.errorServer.errorMessageGetUsers = this.errorLocalesText;
      }
    } else {
      this.errorServer.errorMessageGetUsers = this.errorLocalesText;
    }
  };
  await this.performRequest(config, successCallback, failedCallback);
}
/**
 * Вход пользователя в систему.
 *
 * @type {(this: Admin, requestData: SignInRequest) => Promise<void>}
 * @param {SignInRequest} requestData Данные для входа.
 * @this {Admin}  Экземпляр класса
 */
export async function signIn(this: Admin, requestData: SignInRequest) {
  const config: AxiosRequestConfig = {
    method: MethodRequest.Post,
    url: ROUTES.Login,
    data: requestData,
  };

  try {
    this.isLoading = true;

    const { data } = await axios(config);

    this.userId = data.user_id;
    this.companyId = data.company_id;
    this.isVerifiedUser = true;

    localStorageWrapper.set(StorageKey._UserAuth, {
      userId: data.user_id,
      companyId: data.company_id,
      isVerifiedUser: true,
    });

    Promise.allSettled([
      this.getCompanyInfo(),
      this.getUsersList(),
      this.getFolders(),
    ]);

    await this.setInitSettingsSearch();
  } catch (error: any) {
    if (error?.response) {
      if (error?.response?.data?.message) {
        this.addAlert(error?.response?.data?.message, AlertStatus.error);
        throw error?.response?.data?.message;
      } else {
        this.addAlert(this.errorLocalesText, AlertStatus.error);
        throw this.errorLocalesText;
      }
    } else {
      this.addAlert(this.errorLocalesText, AlertStatus.error);
      throw this.errorLocalesText;
    }
  } finally {
    this.isLoading = false;
  }
}

/**
 * Регистрация нового пользователя.
 *
 * @type {(this: Admin, signUpData: SignUpRequest) => Promise<void>}
 * @param {SignUpRequest} signUpData Данные для регистрации.
 * @this {Admin}  Экземпляр класса
 */
export async function signUp(this: Admin, signUpData: SignUpRequest) {
  const requestData = {
    email: signUpData.email,
    password: signUpData.password,
    company_name: signUpData.companyName.replace(/\s/g, '_'),
    first_name: signUpData.firstName,
    last_name: signUpData.lastName,
    middle_name: signUpData.middleName,
    position: signUpData.position,
  };

  const config: AxiosRequestConfig = {
    method: MethodRequest.Post,
    url: ROUTES.Register,
    data: requestData,
  };

  const successCallback = (data: SignInOrSignUpResponse) => {
    this.userId = data.user_id;
    this.companyId = data.company_id;
    this.isVerifiedUser = true;

    localStorageWrapper.set(StorageKey._UserAuth, {
      userId: data.user_id,
      companyId: data.company_id,
      isVerifiedUser: true,
    });

    Promise.allSettled([
      this.getCompanyInfo(),
      this.getUsersList(),
      this.getFolders(),
    ]);

    this.setInitSettingsSearch();
  };
  const failedCallback = (error: any) => {
    if (error?.response) {
      if (error?.response?.data?.message) {
        throw error?.response?.data?.message;
      } else {
        throw this.errorLocalesText;
      }
    } else {
      throw this.errorLocalesText;
    }
  };
  await this.performRequest(config, successCallback, failedCallback);
}
/**
 * Загрузка файла.
 *
 * @type {(this: Admin, uploadFileData: UploadFileRequest) => Promise<void>}
 * @param {UploadFileRequest} uploadFileData Данные для загрузки файла.
 * @this {Admin}  Экземпляр класса
 */
export async function uploadFile(
  this: Admin,
  uploadFilesData: UploadFileRequest,
) {
  const formData = new FormData();
  formData.append('user_id', this.userId);
  formData.append('company_id', this.companyId);
  formData.append('company_collection_id', uploadFilesData.companyCollectionId);
  formData.append('code', uploadFilesData.code || 'generate');
  uploadFilesData.file.forEach((item) => {
    formData.append('files', item);
  });
  uploadFilesData.objectsToExtractCreate.forEach((item) => {
    formData.append('objects_to_extract_create', item);
  });
  formData.append('count_q_and_a', uploadFilesData.countQAndA || '10');
  formData.append('parser_type', uploadFilesData.parcer_type);

  const config: AxiosRequestConfig = {
    method: MethodRequest.Post,
    url: ROUTES.UploadFile,
    data: formData,
    headers: HEADER_FORM_DATA,
  };

  const successCallback = (data: UploadFileResponst) => {
    this.isOpenModalUploadFile = false;
    this.getDocuments();
    this.addAlert(
      this.textOfAlers.documents.upload.success,
      AlertStatus.success,
    );
  };
  const failedCallback = (error: any) => {
    if (error?.response) {
      if (error?.response?.data?.message) {
        this.addAlert(error?.response?.data?.message, AlertStatus.error);
      } else {
        this.addAlert(this.errorLocalesText, AlertStatus.error);
      }
    } else {
      this.addAlert(this.errorLocalesText, AlertStatus.error);
    }
  };
  await this.performRequest(config, successCallback, failedCallback);
}

/**
 * Получает все бизнес-функции.
 *
 * @type {(this: Admin) => Promise<void>}
 * @this {Admin}  Экземпляр класса
 */

/**
 * Удаляет документ.
 *
 * @type {(this: Admin, documentId: number) => Promise<void>}
 * @param {number} documentId Идентификатор документа, который нужно удалить.
 * @this {Admin}  Экземпляр класса
 */
export async function deleteDocument(
  this: Admin,
  documentId: number,
  name: string,
) {
  const config: AxiosRequestConfig = {
    method: MethodRequest.Delete,
    url: ROUTES.DeleteDocument,
    data: {
      user_id: this.userId,
      document_id: documentId,
      name,
    },
  };

  const successCallback = (data: any) => {
    this.getDocuments();
    this.addAlert(
      this.textOfAlers.documents.delete.success,
      AlertStatus.success,
    );
  };
  const failedCallback = (error: any) => {
    if (error?.response) {
      if (error?.response?.data?.message) {
        this.addAlert(error?.response?.data?.message, AlertStatus.error);
      } else {
        this.addAlert(this.errorLocalesText, AlertStatus.error);
      }
    } else {
      this.addAlert(this.errorLocalesText, AlertStatus.error);
    }
  };
  await this.performRequest(config, successCallback, failedCallback);
}
/**
 * Перезагружает документ в LLM.
 *
 * @type {(this: Admin, documentId: number) => Promise<void>}
 * @param {number} documentId Идентификатор документа, который нужно удалить.
 * @this {Admin}  Экземпляр класса
 */
export async function reloadDocument(
  this: Admin,
  documentId: string,
  name: string,
) {
  this.isLoadingReloadCollection = LoadingStatus.payload;
  const formData = new FormData();
  formData.append('document_id', documentId);
  const config: AxiosRequestConfig = {
    method: MethodRequest.Post,
    url: ROUTES.ReloadDocument,
    data: {
      user_id: this.userId,
      document_id: Number(documentId),
      name,
    },
  };
  let maxId = this.alertArray.length > 0 ? this.alertArray[0].id : 0;
  for (let i = 1; i < this.alertArray.length; i++) {
    const currentItem = this.alertArray[i];
    const previousItem = this.alertArray[i - 1];

    if (currentItem.id === previousItem.id) {
      maxId++;
      currentItem.id = maxId;
    } else if (currentItem.id > maxId) {
      maxId = currentItem.id;
    }
  }
  maxId += 1;
  const successCallback = (data: any) => {
    this.isOpenModalErrorDOc = {
      isOpen: false,
      id: '',
      name: '',
    };
    this.getDocuments();
    this.errorServer.errorOnreloadDoc = '';
    this.alertArray = [
      ...this.alertArray,
      {
        id: maxId,
        title: this.textOfAlers.documents.reload.success,
        status: AlertStatus.success,
        visible: true,
      },
    ];
    this.isLoadingReloadCollection = LoadingStatus.none;
  };
  const failedCallback = (error: any) => {
    this.isLoadingReloadCollection = LoadingStatus.none;
    this.addAlert(this.textOfAlers.documents.reload.error, AlertStatus.error);
    if (error?.response) {
      if (error?.response?.data?.message) {
        this.addAlert(error?.response?.data?.message, AlertStatus.error);
      } else {
        this.addAlert(this.errorLocalesText, AlertStatus.error);
      }
    } else {
      this.addAlert(this.errorLocalesText, AlertStatus.error);
    }
  };
  await this.performRequest(config, successCallback, failedCallback);
}
/**
 * Загружает документ.
 *
 * @type {(this: Admin, documentId: number, name: string, format: string) => Promise<void>}
 * @param {number} documentId Идентификатор документа.
 * @param {string} name Название документа.
 * @param {string} format Формат документа
 * @this {Admin}  Экземпляр класса
 */
export async function downloadDocument(
  this: Admin,
  documentId: number,
  name: string,
  format: string,
) {
  const config: AxiosRequestConfig = {
    method: MethodRequest.Post,
    url: ROUTES.DownloadDocument,
    data: {
      user_id: this.userId,
      document_id: documentId,
      name,
    },
    headers: {
      accept: '*/*',
    },
    responseType: 'blob',
  };

  this.isLoading = true;

  try {
    const { data } = await axios(config);
    downloadFile(data, format, name);
  } catch (error: any) {
    if (error?.response) {
      if (error?.response?.status === 404) {
        this.errorServer.collection = this.errorNotFoundedText;
      } else if (error?.response?.data?.message) {
        this.errorServer.collection = error?.response?.data?.message;
      } else {
        this.errorServer.collection = this.errorLocalesText;
      }
    } else {
      this.errorServer.collection = this.errorLocalesText;
    }
  } finally {
    this.isLoading = false;
  }
}

/**
 * Создает новую бизнес-функцию.
 *
 * @type {(this: Admin, name: string) => Promise<void>}
 * @param {string} name Название бизнес-функции.
 * @this {Admin}  Экземпляр класса
 */

/**
 * Получает список папок.
 *
 * @type {(this: Admin) => Promise<void>}
 * @this {Admin}  Экземпляр класса
 */
export async function getFolders(this: Admin) {
  const config: AxiosRequestConfig = {
    method: MethodRequest.Get,
    url: ROUTES.Folders,
    params: {
      company_id: this.companyId,
    },
  };

  const successCallback = (data: FoldersServer) => {
    this.folders = replaceUnderlineToSpace<Folders>(
      toCamelOrSnakeCase<Folders[]>(data, true),
      'folderName',
    );
  };
  const failedCallback = (error: any) => {
    if (error?.response) {
      if (error?.response?.data?.message) {
        this.errorServer.collection = error?.response?.data?.message;
      } else {
        this.errorServer.collection = this.errorLocalesText;
      }
    } else {
      this.errorServer.collection = this.errorLocalesText;
    }
  };
  await this.performRequest(config, successCallback, failedCallback);
}
/**
 * Получает список документов.
 *
 * @type {(this: Admin) => Promise<void>}
 * @this {Admin}  Экземпляр класса
 */
export async function getDocuments(this: Admin) {
  const config: AxiosRequestConfig = {
    method: MethodRequest.Get,
    url: ROUTES.DocumentsList,
    params: {
      company_id: this.companyId,
    },
  };

  const successCallback = (data: DocumentsData) => {
    const oldDocData = toCamelOrSnakeCase<DocumentsData[]>(data, true);
    this.docData = oldDocData.sort((first, second) => {
      const dateFisrt = new Date(first.uploadDate);
      const dateSecond = new Date(second.uploadDate);
      return dateSecond.getTime() - dateFisrt.getTime();
    });
  };

  await this.performRequest(config, successCallback);
}

/**
 * Создает новую папку.
 *
 * @type {(this: Admin, name: string) => Promise<void>}
 * @param {string} name Название папки.
 * @this {Admin}  Экземпляр класса
 */
export async function createCollection(
  this: Admin,
  name: string,
  integrationName: string,
) {
  if (!this.companyInfo || !this.userInfo)
    throw (this.errorServer.collection = this.errorLocalesText);
  const requestData = {
    company_id: this.companyId,
    integration_folder_name: integrationName
      .replace(/\s/g, '_')
      .trimStart()
      .trimEnd(),
    folder_name: name,
    company_name: this.companyInfo.name.replace(/\s/g, '_'),
    user_id: this.userId,
  };

  const config: AxiosRequestConfig = {
    method: MethodRequest.Post,
    url: ROUTES.CreateFolder,
    data: requestData,
  };

  this.isLoadingCollection = true;
  this.errorServer.collection = '';

  try {
    const { data } = await axios(config);

    if (data?.status_code !== 200) {
      this.errorServer.collection = this.errorLocalesText;
    }
    this.getFolders();
  } catch (error: any) {
    if (error?.response) {
      if (error?.response?.data?.message) {
        this.addAlert(error?.response?.data?.message, AlertStatus.error);
      } else {
        this.addAlert(this.errorLocalesText, AlertStatus.error);
      }
    } else {
      this.addAlert(this.errorLocalesText, AlertStatus.error);
    }
  } finally {
    this.isLoadingCollection = false;
  }
}
/**
 * Удаляет папку.
 *
 * @type {(this: Admin, value: string, label: string) => Promise<void>}
 * @param {string} value Id папки для удаления.
 * @param {string} label Название папки для удаления.
 * @this {Admin}  Экземпляр класса
 */
export async function deleteCollection(
  this: Admin,
  value: string,
  label: string,
) {
  if (!this.companyInfo || !this.userInfo)
    throw (this.errorServer.collection = this.errorLocalesText);

  const integrationFolder = this.folders.find(
    (item) => item.id.toString() === value,
  )?.integrationFolderName;
  const userData = this.userInfo;
  const userFullName =
    !!userData.firstName || !!userData.lastName
      ? `${userData.firstName} ${userData?.middleName || ''}${userData?.middleName ? ' ' : ''}${userData.lastName}`
      : 'No name';

  const requestData = {
    collection_id: value,
    company_id: this.companyId,
    integration_folder_name: integrationFolder,
    folder_name: label.replace(/\s/g, '_'),
    user_name: userFullName,
  };

  const config: AxiosRequestConfig = {
    method: MethodRequest.Delete,
    url: ROUTES.DeleteFolder,
    data: requestData,
  };

  this.isLoadingCollection = true;
  this.errorServer.collection = '';

  try {
    const { data } = await axios(config);

    if (data?.status_code && data?.status_code !== 200) {
      this.addAlert(this.errorLocalesText, AlertStatus.error);
    }
    this.addAlert(this.textOfAlers.folder.delete.success, AlertStatus.success);
    this.getFolders();
  } catch (error: any) {
    if (error?.response) {
      if (error?.response?.data?.message) {
        this.addAlert(error?.response?.data?.message, AlertStatus.error);
      } else {
        this.addAlert(this.errorLocalesText, AlertStatus.error);
      }
    } else {
      this.addAlert(this.errorLocalesText, AlertStatus.error);
    }
  } finally {
    this.isLoadingCollection = false;
  }
}
/**
 * Удаляет бизнес-функцию.
 *
 * @type {(this: Admin, value: string, label: string) => Promise<void>}
 * @param {string} value Значение бизнес-функции для удаления.
 * @param {string} label Название бизнес-функции для удаления.
 * @this {Admin}  Экземпляр класса
 */

/**
 * Отправляет запрос в LLM.
 *
 * @type {(this: Admin, query: string) => Promise<void>}
 * @param {string} query Запрос, отправляемый в LLM.
 * @this {Admin}  Экземпляр класса
 */
export async function onSendMessageLLM(this: Admin, query: string) {
  const searchSettingsUsed = this.settingSearch;
  let messageIndex = 0;
  let isFirstIndex = true;
  this.isLoadingRequestLLM = true;

  if (!searchSettingsUsed) {
    this.errorServer.errorMessageDataRequestLLM =
      'Настройки поиска некорректны';
    this.errorTypeLLM = 'error';

    throw new Error('Настройки поиска некорректны');
  }

  this.errorServer.errorMessageDataRequestLLM = '';

  this.messages = [
    ...this.messages,
    {
      isUserMessage: true,
      message: query,
      date: new Date(),
    },
  ];

  try {
    const response = await fetch(
      process.env.NEXT_PUBLIC_BACK_PROD + ROUTES._UsersRequest,
      {
        method: MethodRequest.Post,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          getDataFormatLLM({ data: searchSettingsUsed, query, isLLM: true }),
        ),
      },
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message);
    }

    const reader = response.body?.getReader();
    const decoder = new TextDecoder('utf-8');

    while (true) {
      const chunk = await reader?.read();
      // @ts-ignore
      const { done, value } = chunk;
      if (done) {
        this.isLoadingRequestLLM = false;
        break;
      }
      const decodedChunk = decoder.decode(value);

      const lines = decodedChunk.split('');
      const parserLines = lines.map((line) => {
        if (line === '') return line.replace('', '\n');
        return line;
      });

      for (const parserLine of parserLines) {
        if (isFirstIndex) {
          this.addMessage({
            isUserMessage: false,
            message: parserLine,
            date: new Date(),
          });
          messageIndex = this.messages.length - 1;
        } else {
          this.updateMessage(messageIndex, parserLine);
        }

        isFirstIndex = false;
      }
    }
  } catch (error: unknown) {
    this.addAlert(
      (error as Error).message || this.errorLocalesText,
      AlertStatus.error,
    );
  } finally {
    this.isLoadingRequestLLM = false;
  }
}

/**
 * Отправляет запрос в LLM.
 *
 * @type {(this: Admin, query: string) => Promise<void>}
 * @param {string} query Запрос.
 * @this {Admin}  Экземпляр класса
 */
export async function onSendMessageRequest(this: Admin, query: string) {
  const searchSettingsUsed = this.settingSearch;

  if (!searchSettingsUsed) throw new Error('');

  this.errorServer.errorMessageDataRequest = '';

  this.isLoadingRequest = true;
  this.errorServer.errorMessageDataRequest = '';

  try {
    const response = await axios({
      method: MethodRequest.Post,
      url: ROUTES._UsersRequest,
      data: getDataFormatLLM({ data: searchSettingsUsed, query }),
    });

    console.log(response);

    const newData = parseMarkdown(response.data.query_result, '');

    if (newData.length === 0) {
      this.errorServer.errorMessageDataRequest = this.errorMessageDataRequest;
      this.errorType = 'warning';
    }

    this.dataSearchNoLLM = [...newData];
  } catch (error: any) {
    this.addAlert(
      error?.response?.data?.message || this.errorLocalesText,
      AlertStatus.error,
    );
    this.errorType = 'error';
  } finally {
    this.isLoadingRequest = false;
  }
}
/**
 * Создает нового пользователя через администратора.
 *
 * @type {(this: Admin, createUserData: CreateUserRequest) => Promise<void>}
 * @param {CreateUserRequest} createUserData Данные для создания нового пользователя.
 * @this {Admin}  Экземпляр класса
 */
export async function createNewUserByAdmin(
  this: Admin,
  createUserData: CreateUserRequest,
) {
  const requestData = {
    first_name: createUserData.firstName,
    last_name: createUserData.lastName,
    middle_name: createUserData.middleName,
    email: createUserData.email,
    company_id: this.companyId,
    settings_id: this.companyId,
    position: createUserData.position,
  };

  const config: AxiosRequestConfig = {
    method: MethodRequest.Post,
    url: ROUTES.SendNewUserInfo,
    data: requestData,
  };

  const successCallback = (data: SignInOrSignUpResponse) => {
    this.setisLoadingAddUser(true);
    this.setInfoAddUSer(true);
    this.getUsersList();
    this.isLoadingAddUser = false;
  };

  const failedCallback = (error: any) => {
    this.errorType = 'error';
    if (error?.response) {
      if (error?.response?.data?.message) {
        this.errorServer.errorMessageCreateUser =
          error?.response?.data?.message;
      } else {
        this.errorServer.errorMessageCreateUser = this.errorLocalesText;
      }
    } else {
      this.errorServer.errorMessageCreateUser = this.errorLocalesText;
    }
  };
  this.errorServer.errorMessageCreateUser = '';
  await this.performRequest(config, successCallback, failedCallback);
}

/**
 * Создает нового пользователя через администратора.
 *
 * @type {(this: Admin, createUserData: CreateUserRequest) => Promise<void>}
 * @param {CreateUserRequest} createUserData Данные для создания нового пользователя.
 * @this {Admin}  Экземпляр класса
 */
export async function sendMessageFromContactWithUs(
  this: Admin,
  sendMessageData: SendMessageContact,
) {
  const requestData = {
    full_name: sendMessageData.fullName,
    email: sendMessageData.email,
    company: sendMessageData.company,
    comment: sendMessageData.comments,
  };

  const config: AxiosRequestConfig = {
    method: MethodRequest.Post,
    url: ROUTES.SendMessageToContact,
    data: requestData,
  };

  const successCallback = () => {
    this.addAlert(this.textOfAlers.contactWithUs.success, AlertStatus.success);
    this.isOpenModalContactWithUs = false;
  };

  const failedCallback = (error: any) => {
    this.errorType = 'error';
    if (error?.response) {
      if (error?.response?.data?.message) {
        this.addAlert(error?.response?.data?.message, AlertStatus.error);
      } else {
        this.addAlert(this.errorLocalesText, AlertStatus.error);
      }
    } else {
      this.addAlert(this.errorLocalesText, AlertStatus.error);
    }
  };
  this.errorServer.errorMessageContact = '';
  await this.performRequest(config, successCallback, failedCallback);
}

/**
 * Создает новый пароль для пользователя.
 *
 * @type {(this: Admin, createPasswordData: CreatePasswordRequest) => Promise<void>}
 * @param {CreatePasswordRequest} createPasswordData Данные для создания нового пароля.
 * @this {Admin}  Экземпляр класса
 */
export async function create_password(
  this: Admin,
  createPasswordData: CreatePasswordRequest,
) {
  const requestData = {
    password: createPasswordData.password,
    token: createPasswordData.token,
  };

  const config: AxiosRequestConfig = {
    method: MethodRequest.Post,
    url: ROUTES.CreateUserByToken,
    data: requestData,
  };

  const successCallback = (data: SignInOrSignUpResponse) => {
    this.userId = data.user_id;
    this.companyId = data.company_id;
    this.isVerifiedUser = true;

    localStorageWrapper.set(StorageKey._UserAuth, {
      userId: data.user_id,
      companyId: data.company_id,
      isVerifiedUser: true,
    });

    Promise.allSettled([
      this.getCompanyInfo(),
      this.getUsersList(),
      this.getFolders(),
    ]);

    this.setInitSettingsSearch();
  };

  await this.performRequest(config, successCallback);
}

export async function clearLLMessage(this: Admin) {
  this.dataSearchNoLLM = [];
}
