/**
 * Список запросов для авторизации
 */
export enum RoutesAuth {
  /**
   * Получить код на почту по email
   */
  SendCode = '/auth/send_request_code',
  /**
   * Получить токены по коду
   */
  TokensByCode = '/auth/get_token_by_code',
  /**
   * Обновить токены входа
   */
  Refresh = '/auth/refresh_token',
  /**
   * Получить токены по паролю
   */
  TokensByPassword = '/auth/get_token_by_password',
  /**
   * Получение пароля при регистрации из письма
   */
  CreatePassword = '/auth/create_password',
  /**
   * Восстановление пароля
   */
  RecoveryPassword = '/users/recovery_password',
  /**
   * Восстановление пароля
   */
  UpdatePassword = '/users/update_password',
  /**
   * Получить информацию из токена
   */
  UserInfo = '/users/info',
  /**
   * Регистрация новой компании
   */
  Register = '/auth/register_company_and_admin',
}
